<template>
  <div>
    <ValidationProvider v-slot="{ errors }" :name="label" :rules="rules">
        <v-text-field
            v-model="cmpValue"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:label="label"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:dense="dense"
            v-bind:hide-details="hideDetails"
            v-bind:error="error"
            v-bind:clearable="clearable"
            v-bind:backgroundColor="backgroundColor"
            v-bind:prefix="options.prefix"
            v-bind:suffix="options.suffix"
            v-on:keypress="keyPress"
            :error-messages="errors"
            v-bind:showAppendOuter="showAppendOuter"
            v-bind:nameIcon="nameIcon"
            v-bind:textTooltip="textTooltip"
            @blur="$emit('onBlur', $event.target.value)"
        >
          <template v-if="showAppendOuter" v-slot:append-outer>
            <v-tooltip top class="pb-7">
                <template v-slot:activator="{ on }">
                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">{{nameIcon}}</v-icon>
                </template>
                <span class="pb-7 textTooltip" v-html="textTooltip"></span>
            </v-tooltip>  
          </template>
          <template v-slot:append>
              <slot name="append-icon" />
          </template>
        </v-text-field>
    </ValidationProvider>

    <!-- <ValidationProvider :name="$attrs.label" :rules="rules">
        <v-text-field
            v-model="cmpValue"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:dense="dense"
            v-bind:hide-details="hideDetails"
            v-bind:error="error"
            v-bind:clearable="clearable"
            v-bind:backgroundColor="backgroundColor"
            v-bind:prefix="options.prefix"
            v-bind:suffix="options.suffix"
            v-on:keypress="keyPress"
            slot-scope="{ errors, valid }"
            :error-messages="errors"
            :success="valid"
            v-bind="$attrs"
            v-on="$listeners"
        ></v-text-field>
    </ValidationProvider> -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  model: { prop: "value", event: "input" },
  props: {
    nameIcon:{
      type:String,
      default:'info'
    },
    textTooltip:{
      type:String,
      default:''
    },
    showAppendOuter:{
      type: Boolean,
      default: false
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      // type: String,
      type: [String, Number],
      default: "0"
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    valueWhenIsEmpty: {
      type: String,
      default: ""  // "0" or "" or null 
    },
    options: {
      type: Object,
      default: function() {
        return {
          locale: "es-MX",
          prefix: "",
          suffix: "",
          length: 11,
          precision: 2
        };
      },
    },
  },
  data: () => ({}),
  
  computed: {
    cmpValue: {
      get: function() {
        return this.value !== null && this.value !== ""
          ? this.humanFormat(this.value.toString())
          : this.valueWhenIsEmpty;
      },
      set: function(newValue) {
        this.$emit("input", this.machineFormat(newValue));
      },
    },
  },
  methods: {
    humanFormat: function(number) {
      if (isNaN(number)) {
        number = "";
      } else {
        number = Number(number).toLocaleString(this.options.locale, {
          maximumFractionDigits: this.options.precision,
          minimumFractionDigits: this.options.precision,
        });
      }
      return number;
    },
    machineFormat(number) {
      if (number) {
        number = this.cleanNumber(number);
        number = number.padStart(parseInt(this.options.precision) + 1, "0");
        number =
          number.substring(
            0,
            number.length - parseInt(this.options.precision)
          ) +
          "." +
          number.substring(
            number.length - parseInt(this.options.precision),
            number.length
          );
        if (isNaN(number)) {
          number = this.valueWhenIsEmpty;
        }
      } else {
        number = this.valueWhenIsEmpty;
      }
      if (this.options.precision === 0) {
        number = this.cleanNumber(number);
      }
      return number;
    },
    keyPress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      if (this.targetLength()) {
        $event.preventDefault();
      }
    },
    cleanNumber: function(value) {
      let result = "";
      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");
        for (var i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              if (arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }
      return result;
    },
    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    },
    targetLength() {
      if (
        Number(this.cleanNumber(this.value).length) >=
        Number(this.options.length)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
  ::v-deep .v-text-field--enclosed .v-input__append-inner{
    margin-top: 0px !important;
    align-self: center;
  }
</style>
