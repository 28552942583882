<template>
    <div>
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay> 
        <v-container xl fluid style="overflow: auto;">
            <v-row>
                <v-col cols="12" xs="12" md="12" lg="12">
                    <v-row class="pa-0 mx-0 mt-0 mb-4">
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <div style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                                <hr class="vr"/>
                                <p class="tblName pb-2">Calculadora de sueldos</p>
                            </div>
                        </v-col>
                        <v-card elevation="0" class="borde-card mx-8 mt-8" min-width="800" >
                            <v-card-text class="pa-50">
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 titleCalculadora">
                                    Calcula el sueldo neto o bruto de tus empleados
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0 d-flex">
                                    <v-switch
                                        v-model="switch1"
                                        label="Bruto a neto"
                                        class="mr-6 custom-switch"
                                    ></v-switch>
                                    <v-switch
                                        v-model="switch2"
                                        label="Neto a bruto"
                                        class="custom-switch"
                                    ></v-switch>
                                </v-col>

                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0">
                                    <v-divider class="my-4"></v-divider>
                                </v-col>

                                <ValidationObserver ref="form" v-slot="{ invalid }">
                                    <v-row class="pa-0 ma-0">
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                v-model="fecha_inicio"
                                                :valorDefecto="fecha_inicio"
                                                label="Fecha de cálculo"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaInicio"
                                                :min="'2021-01-01'"
                                                readonly
                                                v-bind:showAppendOuter="true"
                                                v-bind:textTooltip="'La fecha capturada será utilizada para encontrar <br>los valores del UMA, las tablas de impuesto <br>sobrela renta y el factor de integración.'"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                                            <ValidationProvider v-slot="{ errors }" name="Periodo" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Periodo"
                                                    :items="periodos"
                                                    item-text="nombre"
                                                    item-value="valor"
                                                    persistent-hint
                                                    v-model="valorPeriodo"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors"
                                                    hide-details
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="valorPeriodo == 'quincenal'" cols="12" xs="12" sm="6" md="6" lg="2" xl="2">
                                            <v-checkbox
                                                v-model="checkboxQuincena1520"
                                                label="15.20"
                                                class="mt-3 checkboxVuetify"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                :label="labelSueldo"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="sueldo" 
                                                v-bind:options="options"
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3" v-if="switch1">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                label="Percepción opcional"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="percepcionOpcional" 
                                                v-bind:options="options"
                                                hide-details
                                            />
                                        </v-col>
                                         <v-col v-if="valorPeriodo != 'quincenal'" cols="12" xs="12" sm="6" md="6" lg="2" xl="2">
                                            
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0 pt-4 d-flex">
                                            <v-checkbox
                                                v-model="checkboxIMSS"
                                                label="Incluir IMSS"
                                                class="py-0 my-0 checkboxVuetify"
                                                hide-details
                                            ></v-checkbox>
                                            <v-tooltip top class="pb-2">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-2 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-2 textTooltip">
                                                    Monto de la retención del trabajador por cotizar en <br>
                                                    el seguro social. En referencia a trabajadores que <br>
                                                    perciben un salario mínimo al día, la calculadora <br>
                                                    no realizará ninguna validación, de acuerdo al <br>
                                                    artículo 36 de la LSS.
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0 d-flex">
                                            <v-checkbox
                                                v-model="checkboxSubsidio"
                                                label="Incluir subsidio de empleado"
                                                class="py-0 my-0 checkboxVuetify"
                                                hide-details
                                            ></v-checkbox>
                                            <v-tooltip top class="pb-2">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-2 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-2 textTooltip">
                                                    Monto predeterminado a los trabajadores <br>
                                                    que perciben el salario mínimo.
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-end">
                                            <v-btn 
                                                color="#2DB9D1" 
                                                class="btnAzul elevation-0 px-10"
                                                right
                                                rounded 
                                                :disabled="invalid" 
                                                @click="calcular()"
                                            >
                                                Calcular
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="12" lg="12" class="py-0 my-0">
                                            <v-divider class="my-4"></v-divider>
                                        </v-col>
                                    </v-row>
                                </ValidationObserver>
                                
                                <v-row class="ma-0 px-4" style="max-width:800px;">
                                    <p class="col-12 py-0 px-0 tituloSeccion">{{detalleSueldoTitulo}}</p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Sueldo diario</p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.salario_diario}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Salario base cotización</p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.salario_base_cotizacion}}</span></p>

                                    <v-col cols="12" xs="12" md="12" lg="12" class="pa-0 ma-0">
                                        <v-divider class="my-4"></v-divider>
                                    </v-col>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Límite inferior
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Es el valor mínimo que existe al ubicar <br>
                                                el ingreso en el rango de la tabla de ISR <br>
                                                por cada período.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.limite_inferior}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Excedente del límite inferior
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Valor excedente, que resulta de restar <br>
                                                el valor mínimo del rango de la tabla de <br>
                                                ISR a la base gravable del período.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.excedente_limite_inferior}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Porcentaje sobre el excedente del límite inferior
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Porcentaje que se aplicará al valor excedente <br>
                                                del límite inferior.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{ detalleSueldo.porcentaje + ' %'}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Impuesto marginal
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Es el impuesto resultante al aplicar la tasa <br>
                                                correspondiente.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.impuesto_marginal}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Cuota fija del impuesto
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Monto que se deberá adicionar al impuesto <br>
                                                marginal, que está considerado en la tabla <br>
                                                del ISR.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.cuota_fija}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">ISR causado
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Impuesto según la tarifa del artículo 96 de la LISR.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.isr_causado}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Subsidio causado
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Subsidio correspondido de acuerdo a  la tabla del <br>
                                                artículo 1.12 del Decreto del 26/XII/2013, subsidio <br>
                                                para el empleo.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.subsidio_causado}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">ISR a retener
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Monto de impuesto a retener del periodo, resulta <br>
                                                cuando el subsidio causado es menor al ISR <br>
                                                causado.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.isr_retener}}</span></p>

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">Subsidio a entregar
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="outlined_v_icon">info</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Subsidio al empleo a entregar, resulta cuando el <br>
                                                subsidio causado es mayor al ISR causado.
                                            </span>
                                        </v-tooltip>
                                    </p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.subsidio_entregar}}</span></p>

                                    

                                    <!-- <p class="col-6 py-0 px-0 mb-2 subtitle">Subsidio al empleo:</p>
                                    <p class="col-6 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.subsidio}}</span></p> -->

                                    <p class="col-6 py-0 px-0 mb-2 subtitle">IMSS</p>
                                    <p class="col-5 py-0 px-0 mb-2 text"><span >{{"$ " + detalleSueldo.imss}}</span></p>
                                </v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0" style="max-width:800px;">
                                    <v-divider class="divider"></v-divider>
                                </v-col>

                                <v-row class="ma-0 px-4" style="max-width:800px;">
                                    <p class="col-12 py-0 px-0 tituloSeccion">{{titleSueldoTotal}}</p>
                                    <v-col cols="12" xs="10" sm="8" md="8" lg="10" xl="10" class="pa-0">
                                        <!-- <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            disabled
                                            class="pa-0 ma-0 custom-input"
                                            prefix="$"
                                            v-model="sueldoTotal" 
                                            v-bind:options="options"
                                            hide-details
                                        /> -->
                                        <v-text-field
                                            outlined
                                            hide-details
                                            disabled
                                            v-model="sueldoTotalComputed"
                                            class="pa-0 ma-0 custom-text-field"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="2" sm="4" md="4" lg="2" xl="2" class="pa-0">
                                        <v-btn
                                            color="#FFF"
                                            class="elevation-0 ml-2 btnxls"
                                            @click="reporte()"
                                            height="52"
                                            width="20"
                                            :disabled="descargarDisable"
                                            style="border-radius: 15px; box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);"
                                        >
                                            <img src="/static/icon/xls.png" style="width:30px" :style="descargarDisable?'opacity: 0.5;':'opacity: 1.0;'"/>
                                        </v-btn>
                                    </v-col>
                                    
                                    
                                </v-row>
                                <v-row class="ma-0 px-4 mt-4">
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
                                        <p class="subtitle">
                                            Esta calculadora solo contempla las prestaciones mínimas de ley menor a 1 año de antigüedad. Los resultados del ejercicio son únicamente de carácter informativo y es responsabilidad del usuario su uso correcto.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import apiCalculadora from '@/api/nominas/calculadora';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import Notify from '@/plugins/notify';
import moment from 'moment';
moment.locale('es');
export default {
    components: {
        VuetifyDatePicker,
        VuetifyMoneyValidate,
    },
    data() {
        return {
            switch1: true,
            switch2: false,
            periodos: [
                {id:1, nombre:"Semanal", valor:"semanal"},
                {id:2, nombre:"Catorcenal", valor:"catorcenal"},
                {id:3, nombre:"Quincenal", valor:"quincenal"},
                {id:4, nombre:"Mensual", valor:"mensual"}
            ],
            valorPeriodo: 'semanal',
            sueldo:0,
            checkboxIMSS:false,
            checkboxSubsidio:false,
            sueldoTotal:0.00,
            fecha_inicio: null,
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            salarioDiario:0,
            salarioBaseCotizacion:0,
            percepcionOpcional:0,
            loadingLottie: false,
            fraseLoading: "Descargando",
            detalleSueldo: {
                salario_diario              : '0.00',
                salario_base_cotizacion     : '0.00',
                limite_inferior             : '0.00',
                excedente_limite_inferior   : '0.00',
                porcentaje                  : '0.00',
                impuesto_marginal           : '0.00',
                cuota_fija                  : '0.00',
                isr_causado                 : '0.00',
                isr_retener                 : '0.00',
                imss                        : '0.00',
                subsidio_entregar           : '0.00',
                subsidio_causado            : '0.00',
                importe_bruto               : '0.00',
                importe_neto                : '0.00' 
            },
            checkboxQuincena1520    : false,
        }
    },
    watch:{
        switch1:function(val){
            this.switch2 = !val
        },
        switch2:function(val){
            this.switch1 = !val;
            this.percepcionOpcional = 0;
        },
        valorPeriodo: function(val){
            if(val != 'quincenal'){
                this.checkboxQuincena1520 = false;
            }
        }
    },
    computed: {
        sueldoTotalComputed:function(){
            if(this.sueldoTotal == 0){
                return "$ 0.00"
            }
            else{
                return "$ "+this.sueldoTotal
            }
        },
        labelSueldo: function(){
            if(this.switch1){
                switch (this.valorPeriodo) {
                    case 'semanal':
                        return 'Sueldo bruto semanal';
                        break;
                    case 'catorcenal':
                        return 'Sueldo bruto catorcenal';
                        break;
                    case 'quincenal':
                        return 'Sueldo bruto quincenal';
                        break;
                    case 'mensual':
                        return 'Sueldo bruto mensual';
                        break;
                    default:
                        return '';
                        break;
                }
            }
            else{
                switch (this.valorPeriodo) {
                    case 'semanal':
                        return 'Sueldo neto semanal';
                        break;
                    case 'catorcenal':
                        return 'Sueldo neto catorcenal';
                        break;
                    case 'quincenal':
                        return 'Sueldo neto quincenal';
                        break;
                    case 'mensual':
                        return 'Sueldo neto mensual';
                        break;
                    default:
                        return '';
                        break;
                }
            }
        },
        detalleSueldoTitulo: function(){
            if(!this.switch1){
                return 'Detalle sueldo'
            }
            else{
                return 'Detalle sueldo'
            }
        },
        titleSueldoTotal: function(){
            if(!this.switch1){
                switch (this.valorPeriodo) {
                    case 'semanal':
                        return 'Sueldo total bruto semanal';
                        break;
                    case 'catorcenal':
                        return 'Sueldo total bruto catorcenal';
                        break;
                    case 'quincenal':
                        return 'Sueldo total bruto quincenal';
                        break;
                    case 'mensual':
                        return 'Sueldo total bruto mensual';
                        break;
                    default:
                        return 'Sueldo total';
                        break;
                }
            }
            else{
                switch (this.valorPeriodo) {
                    case 'semanal':
                        return 'Sueldo total neto semanal';
                        break;
                    case 'catorcenal':
                        return 'Sueldo total neto catorcenal';
                        break;
                    case 'quincenal':
                        return 'Sueldo total neto quincenal';
                        break;
                    case 'mensual':
                        return 'Sueldo total neto mensual';
                        break;
                    default:
                        return 'Sueldo total';
                        break;
                }
            }
        },
        descargarDisable: function(){
            return (this.valorPeriodo != null && this.sueldo != null && this.valorPeriodo != '' && this.sueldo != '')?false:true;
        }
    },
    methods: {
        calcular(){

            let parametros = { 
                periodo:this.valorPeriodo, 
                importe_neto        : '0.00', 
                importe_bruto       : '0.00',
                incluir_imss        : this.checkboxIMSS,
                incluir_subsidio    : this.checkboxSubsidio,
                is_asimilado        : false,
                quincenal_op2       : this.checkboxQuincena1520,
                percepcion_extra    : this.percepcionOpcional,
                fecha_inicio        : this.fecha_inicio
            }
            if(this.switch1){
                parametros.importe_bruto = this.sueldo;
                parametros.importe_neto = '0.00';
            }
            else{
                parametros.importe_bruto = '0.00';
                parametros.importe_neto = this.sueldo;
            }

            this.loadingLottie = true;
            this.fraseLoading = "Calculando";

            apiCalculadora.calcular(parametros).then((response) => {
                this.detalleSueldo = response;

                if(this.switch1){
                    //this.sueldoTotal = response.importe_neto;
                    this.sueldoTotal = parseFloat((response.importe_neto).replace(',', ''));
                }
                else{
                    //this.sueldoTotal = response.importe_bruto;
                    this.sueldoTotal = parseFloat((response.importe_bruto).replace(',', ''));
                }

                this.loadingLottie = false;
            })
            .catch(err => {
                this.loadingLottie = false;
                console.log(err);
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });

        },
        reporte(){
            const FileDownload = require("js-file-download");
            var axios = require('axios');
            let parametros = { 
                periodo:this.valorPeriodo, 
                importe_neto        : '0.00', 
                importe_bruto       : '0.00',
                incluir_imss        : this.checkboxIMSS,
                incluir_subsidio    : this.checkboxSubsidio,
                is_asimilado        : false,
                quincenal_op2       : this.checkboxQuincena1520,
                percepcion_extra    : this.percepcionOpcional,
                fecha_inicio        : this.fecha_inicio
            }
            if(this.switch1){
                parametros.importe_bruto = this.sueldo;
                parametros.importe_neto = '0.00';
            }
            else{
                parametros.importe_bruto = '0.00';
                parametros.importe_neto = this.sueldo;
            }

            this.loadingLottie = true;
            this.fraseLoading = "Calculando";

            let nombre;
            nombre = "Cálculo.xlsx";
            let self = this;

            let url = "calculo-nomina/reporte-calculo-inverso";
            try {
                axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.loadingLottie = false;
                })
                .catch(async (error) =>{
                    self.loadingLottie = false;
                    console.log(error);
                    let errorString = error.response;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response instanceof Blob &&
                        error.response.type &&
                        error.response.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } 
            catch (err) {
                self.loadingLottie = false;
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
            }
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(numero){
            let num = parseFloat(numero)
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
    },
    created(){
        this.fecha_inicio = moment().format('YYYY-MM-DD');
    }
    
}
</script>
<style scoped>
    .btnAzul.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-transform: capitalize;
        transition: 0.3s;
        height: 38px !important;
        background: #58C5D8;
        border-radius: 50px;
    }

    .btnAzul.theme--light.v-btn:hover {
        background: #2DB9D1 !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
    }
    .tituloSeccion{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #1E2245;
    }
    .titleCalculadora{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #4F4F4F;
        display: flex;
        justify-content:end;
    }
    .pa-50{
        padding: 50px 50px 50px 50px;
    }

    ::v-deep .custom-text-field.v-text-field input{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #4F4F4F;

        text-align:end;
    }

    .btnxls.theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
        background-color: transparent!important;
    }
</style>