import Vue from 'vue';

export default {
    /* calcular(parametros) {
        return Vue.axios.post("testing/generar-calculo-inverso", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    } */
    calcular(parametros) {
        return Vue.axios.post("calculo-nomina/generar-calculo-inverso", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }

    
}